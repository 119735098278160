var generic = generic || {};

$(document).ready(function() {
  var $usLegalUpdate = $('.us-legal-update-text');
  var $emeaLegalUpdate = $('.emea-legal-update-text');

  if (generic.cookie('LOCALE') !== 'en_US') {
    $usLegalUpdate.addClass('hidden');
    $emeaLegalUpdate.removeClass('hidden');
  }
});
